import GoogleRating from '@/components/GoogleRating';
import GoogleReview from '@/components/GoogleReview';
import { MetaHeader } from '@/components/MetaHeader';
import { Content } from '@/components/shared/Content';
import { CheckIcon as ChakraCheckIcon, CloseIcon as ChakraCloseIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { MainLayout } from 'layouts/MainLayout';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { HiOutlinePhone } from 'react-icons/hi';

function Pill(props: PropsWithChildren) {
    return (
        <span
            {...props}
            className={
                'bg-azure-600 text-white p-[8px] rounded-[48px] text-center text-body-3 font-900 py-[4px] px-[12px] shadow-md flex justify-center items-center w-fit mx-auto'
            }
        />
    );
}

export default function Home() {
    const router = useRouter();
    const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
    const og_image = `${BASE_URL}/images/og/og-prestamo.jpg`;
    const description =
        'Obtén un préstamo usando tu coche como garantía y manteniendo la titularidad. Sin complicaciones. Valoramos tu coche al instante para ofrecerte la mejor oferta del mercado.';

    function handleRouterPush() {
        router.push('/lead-form');
    }

    return (
        <MainLayout>
            <MetaHeader og_image={og_image} description={description} />
            <section className="h-full bg-lavender-100">
                <Content className={'mx-auto h-full relative md:grid md:grid-cols-2 md:gap-2 md:justify-around md:px-4'} withoutPadding>
                    <div className="flex justify-center mb-24 md:mb-0 md:place-content-center pl-[16px] py-[28px] md:pt-[60px] xl:py-[90px]">
                        <div className="heroCTA flex md:rounded-bl-[100px]">
                            <div className={'flex flex-col gap-10 md:justify-center flex-1 text-black'}>
                                <div className={'space-y-6 md:space-y-10 xl:space-y-16'}>
                                    <h1 className="text-azure-800 font-900 text-[2.375rem] md:text-[3.375rem] leading-[42px] md:leading-[50px] xl:leading-[64px]">
                                        Consigue tu préstamo bancario con tu coche como garantía
                                    </h1>
                                    <div>
                                        <Button
                                            id="HM-CTA-OFFER"
                                            variant={'primary'}
                                            bg={''}
                                            className={
                                                'bg-gradient-orange h-[52px] text-white font-500 leading-[1.5rem] rounded-[8px] md:w-[300px] xl:w-[400px]'
                                            }
                                            onClick={handleRouterPush}
                                        >
                                            {'Solicitar oferta'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:flex md:justify-center md:items-center">
                        <Image
                            className="invisible md:visible max-w-full max-h-full"
                            src="/images/clistina.png"
                            alt="Isologo Clidrive"
                            width={660}
                            height={660}
                        />
                    </div>

                    <Image
                        className={'absolute bottom-0 right-0 sm:w-[350px] md:hidden'}
                        src={'/images/new-landing/isologo.svg'}
                        alt={'Isologo Clidrive'}
                        width={250}
                        height={250}
                    ></Image>
                </Content>
            </section>
            <section>
                <Content className={'flex flex-col items-center mx-auto px-[16px] py-10 xl:py-[40px]'}>
                    <div className={'flex flex-col gap-[24px] md:justify-center flex-1 text-black md:mx-auto md:py-10'}>
                        <div className="flex flex-col gap-1 md:gap-2">
                            <h2 className="text-[2.375rem] md:text-[43px] xl:text-[48px] leading-[42px] text-center font-900 text-azure-800">
                                ¿Necesitas liquidez?
                            </h2>
                            <p className={'text-body-1 md:text-[32px] text-center font-600 md:font-500 text-azure-800'}>
                                Tu préstamo bancario con las mejores condiciones
                            </p>
                        </div>

                        <div className="flex flex-col px-4 xl:grid xl:grid-cols-3 gap-10 my-8">
                            <div className="flex gap-6 items-center justify-start">
                                <Image
                                    className={'w-[64px] h-[84px] md:w-[100px] md:h-[100px]'}
                                    src={'/images/new-landing/isotipo.svg'}
                                    alt={''}
                                    height={84}
                                    width={64}
                                />
                                <div className={'space-y-2'}>
                                    <p className={'text-body-3 leading-[18px] font-700 text-azure-800'}>En 24 horas</p>
                                    <p className={'text-body-3 leading-[18px] text-azure-800'}>
                                        Evita retrasos innecesarios y recibe el dinero cuanto antes.
                                    </p>
                                </div>
                            </div>
                            <div className="flex gap-6 items-center justify-start">
                                <Image
                                    className={'w-[64px] h-[84px] md:w-[100px] md:h-[100px]'}
                                    src={'/images/new-landing/isotipo.svg'}
                                    alt={''}
                                    height={84}
                                    width={64}
                                />
                                <div className={'space-y-2'}>
                                    <p className={'text-body-3 leading-[18px] font-700 text-azure-800'}>Hasta el 100% del valor</p>
                                    <p className={'text-body-3 leading-[18px] text-azure-800'}>
                                        Estudiamos tu caso y valoramos tu vehículo al instante.
                                    </p>
                                </div>
                            </div>
                            <div className="flex gap-6 items-center justify-start">
                                <Image
                                    className={'w-[64px] h-[84px] md:w-[100px] md:h-[100px]'}
                                    src={'/images/new-landing/isotipo.svg'}
                                    alt={''}
                                    height={84}
                                    width={64}
                                />
                                <div className={'space-y-2'}>
                                    <p className={'text-body-3 leading-[18px] font-700 text-azure-800'}>Sin dejar de conducir tu coche</p>
                                    <p className={'text-body-3 leading-[18px] text-azure-800'}>
                                        No tendrás que preocuparte por desplazamientos para valorar tu coche como garantía.
                                    </p>
                                </div>
                            </div>
                            <div className={'flex justify-center col-span-3'}>
                                <Button
                                    id="HM-CTA-OFFER-FREE"
                                    variant={'primary'}
                                    bg={''}
                                    className={'bg-gradient-orange h-[52px] text-white font-500 leading-[1.5rem] rounded-[8px]'}
                                    onClick={handleRouterPush}
                                >
                                    {'Solicitar evaluación gratuita'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Content>
            </section>

            <section className="bg-lavender-400">
                <Content className={'flex flex-col items-center gap-10 pt-4 pb-10 mx-auto xl:py-16'}>
                    <div>
                        <div className={'flex justify-center col-span-3'}>
                            <Image
                                className={'w-[64px] h-[84px] md:w-[100px] md:h-[100px] xl:mb-10'}
                                src={'/images/new-landing/isotipo.svg'}
                                alt={''}
                                height={84}
                                width={64}
                            />
                        </div>
                        <div className={'flex justify-center col-span-3'}>
                            <p className={'text-body-3 leading-[18px] text-azure-800 text-center font-bold'}>
                                Clidrive, la única plataforma que te conecta con bancos para obtener más dinero usando tu coche como
                                garantía.
                            </p>
                        </div>
                    </div>
                </Content>
            </section>

            <section>
                <Content className={'flex flex-col items-center gap-10 pt-10 pb-12 mx-auto xl:py-20'}>
                    <h2 className="text-[2.25rem] md:text-[38px] text-center font-900 text-azure-800">Descubre nuestras ventajas </h2>
                    <table className="w-full xl:w-4/5">
                        <tbody className="space-y-96">
                            <TableRow>
                                <th className="w-2/5"></th>
                                <th className="text-azure-500">Clidrive</th>
                                <th>Otros</th>
                            </TableRow>
                            <TableRow className="min-h-20">
                                <td className="w-2/5 font-bold">Hasta el 100% del valor del coche</td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                                <td className="text-center">
                                    <CloseIcon></CloseIcon>
                                </td>
                            </TableRow>
                            <TableRow className="bg-lavender-400 min-h-32">
                                <td className="w-2/5 font-bold">Dinero en 24 horas</td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td className="w-2/5 font-bold">Sin cambiar titularidad</td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                                <td className="text-center">
                                    <CloseIcon></CloseIcon>
                                </td>
                            </TableRow>
                            <TableRow className="bg-lavender-400">
                                <td className="w-2/5 font-bold">Cuota adaptable</td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td className="w-2/5 font-bold">Sin permanencias</td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                            </TableRow>
                            <TableRow className="bg-lavender-400">
                                <td className="w-2/5 font-bold">Sin coche en depósito</td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                                <td className="text-center">
                                    <CloseIcon></CloseIcon>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td className="w-2/5 font-bold">Seguros incluidos</td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                                <td className="text-center">
                                    <CloseIcon></CloseIcon>
                                </td>
                            </TableRow>
                            <TableRow className="bg-lavender-400">
                                <td className="w-2/5 font-bold">Sin límite de crédito</td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                                <td className="text-center">
                                    <CloseIcon></CloseIcon>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td className="w-2/5 font-bold">Sin papeleos. 100% online</td>
                                <td className="text-center">
                                    <CheckIcon></CheckIcon>
                                </td>
                                <td className="text-center">
                                    <CloseIcon></CloseIcon>
                                </td>
                            </TableRow>
                        </tbody>
                    </table>
                    <p className="md:w-3/4 xl:w-2/3">
                        Conseguir crédito bancario usando tu coche como garantía nunca ha sido tan sencillo. Evaluamos tu coche y te
                        conectamos con bancos para ofrecerte la mejor oferta financiera en pocos minutos.
                    </p>
                </Content>
            </section>

            <section className="bg-lavender-400">
                <Content className={'flex flex-col items-center gap-10 pt-4 pb-10 mx-auto xl:py-16'}>
                    <h2 className="text-[2.25rem] md:text-[38px] text-center font-900 text-azure-800">
                        Ya contamos con más de 1.500 clientes satisfechos
                    </h2>
                    <GoogleRating rating={4.5} />
                    <div className="flex flex-col md:flex-row gap-8">
                        <GoogleReview stars={5} date="Julio 2023" name="César" avatarSrc="/images/new-landing/google-reviews-avatar-1.svg">
                            <p>
                                A pesar de mis circunstancias <span className="font-700">hicieron que todo fuese mucho más fácil</span>.
                                Agradecer en todo momento a María su trato hacia mi persona.
                            </p>
                        </GoogleReview>
                        <GoogleReview stars={5} date="Junio 2023" name="Juanjo" avatarSrc="/images/new-landing/google-reviews-avatar-2.svg">
                            <p>
                                La{' '}
                                <span className="font-700">
                                    amabilidad y el trato de Jorge en mi caso no fue magnífico, fue más que eso!
                                </span>{' '}
                                Un profesional brutal enhorabuena por tenerlo en la empresa.
                            </p>
                        </GoogleReview>
                        <GoogleReview stars={5} date="Mayo 2023" name="María" avatarSrc="/images/new-landing/google-reviews-avatar-3.svg">
                            <p>
                                A mi atendió Eloy y desde luego <span className="font-700">lo voy a recomendar sin duda</span>, son lo mejor
                                del mercado y <span className="font-700">todo muy transparente</span>. Gracias al equipo de clidrive!
                            </p>
                        </GoogleReview>
                    </div>
                </Content>
            </section>

            <section>
                <Content className={'py-12 p-8 md:px-20 md:py-20 md:space-y-0 max-w-[328px] mx-auto gap-8'}>
                    <div className="md:flex flex-col justify-between">
                        <h2 className="text-[2.375rem] md:text-[43px] xl:text-[48px] leading-[42px] font-900 text-center text-azure-800">
                            ¿Tienes dudas o necesitas ayuda?
                        </h2>
                    </div>
                    <div className="space-y-4 flex flex-col justify-center">
                        <div className="text-center text-[1.8rem]">
                            <h3 className="font-500 text-azure-800">Llámanos gratis</h3>
                        </div>
                        <div className={'flex justify-center'}>
                            <Button
                                id={'HM-CTA-PHONE'}
                                variant={'primary'}
                                bg={'#0081A8'}
                                className={'bg-white h-[52px] text-azure-500 font-500 leading-[1.5rem] rounded-[8px] flex gap-2'}
                                onClick={() => router.push('tel:911239287')}
                            >
                                <HiOutlinePhone className={'w-6 h-6'} /> {'+34 911 23 92 87'}
                            </Button>
                        </div>
                        <Image
                            className={'mx-auto w-[224px] h-[159px] '}
                            src={'/images/new-landing/faq-agent-help.svg'}
                            alt={''}
                            height={224}
                            width={159}
                        />
                    </div>
                </Content>
            </section>

            <section className={'bg-lavender-400'}>
                <Content className={'py-12 p-8 md:px-20 md:py-20 max-w-[328px] mx-auto gap-8 space-y-8 md:space-y-12'}>
                    <div className="md:flex flex-col justify-between">
                        <h2 className="text-[2.375rem] md:text-[43px] xl:text-[48px] leading-[42px] font-900 text-center text-azure-800">
                            Consigue tus objetivos
                        </h2>
                    </div>
                    <div className="space-y-4 flex flex-col justify-center w-fit mx-auto xl:flex-row xl:space-y-0 xl:gap-10">
                        <p className={'flex flex-col text-azure-800'}>
                            <span className={'font-bold text-lg'}>Continúa formándote</span>
                            Usa tu coche como garantía y consigue dinero para ese máster que necesitas.
                        </p>
                        <p className={'flex flex-col text-azure-800'}>
                            <span className={'font-bold text-lg'}>Emprende</span>
                            Consigue impulso económico para tu empresa usando tu coche como garantía.
                        </p>
                        <p className={'flex flex-col text-azure-800'}>
                            <span className={'font-bold text-lg'}>Reforma tu vivienda</span>
                            Consigue dinero por tu coche y remodela tu vivienda.
                        </p>
                    </div>
                </Content>
            </section>
            {/*<HelperBot />*/}
        </MainLayout>
    );
}

export function TableRow({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) {
    return <tr className={`md:h-10 ${className}`}>{children}</tr>;
}

export function CloseIcon({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) {
    return (
        <ChakraCloseIcon className={`border-2 rounded-full border-red-500 p-0.5 ${className}`} color={'red.500'}>
            {children}
        </ChakraCloseIcon>
    );
}

export function CheckIcon({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) {
    return (
        <ChakraCheckIcon className={`border-2 rounded-full border-azure-600 p-0.5 ${className}`} color={'#0081A8'}>
            {children}
        </ChakraCheckIcon>
    );
}
